import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainBlue,
  lightGray,
  mainBlack,
  graphik,
  larsseit,
  screen,
} from "../components/common/variables";
import AnimatedHeading from "../components/animated-heading/what-we-do-page";
import CarouselTestimonials from "../components/carousel-testimonials";
import ContactForm from "../components/contact-form";

const Wrapper = styled.div`
  background-color: ${mainWhite};

  .service-intro {
    padding: 0 26px;
    @media ${screen.xsmall} {
      padding: 0 60px;
    }
    @media ${screen.small} {
      padding: 0 80px;
    }
    @media ${screen.medium} {
      padding: 0 7vw;
    }
    &__heading {
      margin-bottom: 40px;
      @media ${screen.xlarge} {
        margin-bottom: 60px;
      }

      h1 {
        color: ${mainBlack};
        font-family: ${larsseit};
        line-height: 1.23;
        font-size: 2.53rem;
        @media ${screen.xsmall} {
          font-size: 3.2rem;
        }
        @media ${screen.small} {
          font-size: 4rem;
        }
        @media ${screen.medium} {
          font-size: clamp(1.4rem, calc(1.4rem + 3vw), 5.01rem);
        }

        strong {
          color: ${mainBlue};
        }

        .break {
          @media ${screen.xsmall} {
            display: block;
          }
        }
      }
    }

    &__description {
      color: ${mainBlack};
      max-width: 950px;
      font-family: ${graphik};

      p {
        font-size: 1.1rem;
        margin: 0 0 20px 0;
        @media ${screen.small} {
          font-size: 1.15rem;
        }
        @media ${screen.medium} {
          font-size: 1.33rem;
          margin: 0 0 30px 0;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &__featured-img {
      display: flex;
      @media ${screen.xsmall} {
        display: none;
      }

      .img-left {
        width: 100%;
        background-image: ${(props) => `url(${props.featured_image_left})`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-left: 26px;
        margin-top: 70px;
        margin-bottom: 70px;
        height: calc(200px + 5vw);
        @media ${screen.xsmall} {
          width: 60%;
          height: auto;
          margin-left: 0;
          margin-right: 16px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .img-right {
        width: 100%;
        height: calc(200px + 5vw);
        background-image: ${(props) => `url(${props.featured_image_right})`};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 26px;
        margin-bottom: 70px;
        @media ${screen.xsmall} {
          width: 40%;
          height: auto;
          margin-right: 0;
          margin-bottom: 0;
        }
        @media ${screen.medium} {
        }
      }

      &--lg {
        display: none;
        @media ${screen.xsmall} {
          display: flex;
          height: calc(200px + 17vw);
          margin: 0 0 50px 0;
        }
        @media ${screen.small} {
          height: calc(200px + 17vw);
          margin: 0 0 70px 0;
        }
        @media ${screen.xlarge} {
          margin: 0 0 90px 0;
        }
      }
    }
  }

  .service-highlighted {
    color: ${mainBlack};
    font-family: ${graphik};
    margin: 0;
    @media ${screen.xsmall} {
      margin: 80px 0 0 0;
    }
    @media ${screen.small} {
      margin: 90px 0 0 0;
    }
    @media ${screen.xlarge} {
      margin: 120px 0 0 0;
    }

    &__row {
      padding: 40px 26px;
      @media ${screen.xsmall} {
        padding: 50px 60px;
        display: flex;
      }
      @media ${screen.small} {
        padding: 70px 80px;
      }
      @media ${screen.medium} {
        padding: 70px 7vw;
      }
      @media ${screen.xlarge} {
        padding: 90px 7vw;
      }

      .heading {
        font-family: ${larsseit};
        font-size: 1.5rem;
        margin-bottom: 22px;
        @media ${screen.xsmall} {
          width: 40%;
          margin-bottom: 0;
          font-size: 1.2rem;
        }
        @media ${screen.small} {
          width: 35%;
          font-size: 1.4rem;
        }
        @media ${screen.medium} {
          width: 32%;

          font-size: clamp(1.2rem, calc(0.5rem + 1vw), 1.7rem);
        }
      }

      .description {
        @media ${screen.xsmall} {
          width: 60%;
        }
        @media ${screen.small} {
          width: 65%;
        }
        @media ${screen.medium} {
          width: 68%;
        }
        p {
          font-size: 1.1rem;
          margin: 0 0 20px 0;
          max-width: 820px;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
          @media ${screen.medium} {
            font-size: 1.33rem;
            margin: 0 0 30px 0;
          }
        }
      }

      .list {
        font-size: 1rem;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        @media ${screen.medium} {
          font-size: clamp(1.1rem, calc(0.2rem + 1vw), 1.41rem);
        }

        li {
          color: #636466;
          margin-bottom: 12px;
          font-weight: 400;
        }
      }

      &--1st {
        background: #e3e3e3;
      }

      &--2nd {
        background: #efefef;
      }

      &--3rd {
        background-color: #cecece;
      }
      &--4th {
        background-color: #ffffff;
      }
    }
  }
`;
const WhatWeDoPage = ({ data }) => {
  const {
    title_tag,
    meta_description,
    intro_description,
    featured_image_left,
    featured_image_right,
    first_row_heading,
    first_row_description,
    first_row_list,
    second_row_heading,
    second_row_description,
    second_row_list,
    third_row_heading,
    third_row_description,
    third_row_list,
    fourth_row_heading,
    fourth_row_description,
    fourth_row_list,
    testimonial_list,
  } = data.content.data;

  const url = typeof window !== "undefined" && window.location.href;

  return (
    <Layout colourNavbar={mainWhite}>
      <SEO
        title={title_tag}
        description={meta_description}
        image={featured_image_right.url}
        webPageInfo={{
          name: title_tag,
          url,
          description: meta_description,
        }}
      />
      <Wrapper
        featured_image_right={featured_image_right.url}
        featured_image_left={featured_image_left.url}
      >
        <div className="service-intro">
          <div className="service-intro__heading">
            <AnimatedHeading />
          </div>

          <div
            className="service-intro__description"
            dangerouslySetInnerHTML={{ __html: intro_description.html }}
          />
        </div>

        <div className="service-intro__featured-img">
          <div className="img-left"></div>
        </div>

        <div className="service-highlighted">
          <div className="service-highlighted__row service-highlighted__row--1st">
            <h4 className="heading" data-sal="slide-up" data-sal-duration="500">
              {first_row_heading}
            </h4>
            <div
              className="description"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                dangerouslySetInnerHTML={{ __html: first_row_description.html }}
              />
              <ul className="list">
                {first_row_list.map((item) => (
                  <li key={item.text}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="service-highlighted__row service-highlighted__row--2nd">
            <h4 className="heading" data-sal="slide-up" data-sal-duration="500">
              {second_row_heading}
            </h4>
            <div
              className="description"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: second_row_description.html,
                }}
              />
              <ul className="list">
                {second_row_list.map((item) => (
                  <li key={item.text}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="service-highlighted__row service-highlighted__row--3rd">
            <h4 className="heading" data-sal="slide-up" data-sal-duration="500">
              {third_row_heading}
            </h4>
            <div
              className="description"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                dangerouslySetInnerHTML={{ __html: third_row_description.html }}
              />
              <ul className="list">
                {third_row_list.map((item) => (
                  <li key={item.text}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="service-highlighted__row service-highlighted__row--4th">
            <h4 className="heading" data-sal="slide-up" data-sal-duration="500">
              {fourth_row_heading}
            </h4>
            <div
              className="description"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: fourth_row_description.html,
                }}
              />
              <ul className="list">
                {fourth_row_list.map((item) => (
                  <li key={item.text}>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div
          className="service-intro__featured-img"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          <div className="img-right"></div>
        </div>

        <div
          className="service-intro__featured-img service-intro__featured-img--lg"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          <div className="img-left"></div>
          <div className="img-right"></div>
        </div>

        <CarouselTestimonials testimonial_list={testimonial_list} />

        <ContactForm />
      </Wrapper>
    </Layout>
  );
};

export default WhatWeDoPage;

export const dataQuery = graphql`
  {
    content: prismicWhatWeDoPage {
      data {
        title_tag
        meta_description
        intro_description {
          html
        }
        featured_image_left {
          alt
          url
        }
        featured_image_right {
          alt
          url
        }
        first_row_heading
        first_row_description {
          html
        }
        first_row_list {
          text
        }
        second_row_heading
        second_row_description {
          html
        }
        second_row_list {
          text
        }
        third_row_heading
        third_row_description {
          html
        }
        third_row_list {
          text
        }
        fourth_row_heading
        fourth_row_description {
          html
        }
        fourth_row_list {
          text
        }
        testimonial_list {
          full_name
          company
          heading {
            html
          }
          message {
            html
          }
        }
      }
    }
  }
`;
